import React, {Component} from "react";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import DateUtil from "../../../../utils/DateUtil";
import AppointmentUtil from "../../../../utils/AppointmentUtil";
import moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button, ButtonGroup} from "react-bootstrap";
import RemotingService from "../../../../services/remoting-service/RemotingService";
import {Badge, Modal} from "../../../../components";
import EnumAppointmentType from "../../../appointment/enums/EnumAppointmentType";
import EnumAppointmentStatus from "../../../appointment/enums/EnumAppointmentStatus";
import AppointmentDocuments from "../../../appointment/document/AppointmentDocuments";
import AddNoteButton from "../../../appointment/note/AddNoteButton";
import AppointmentNotes from "../../../appointment/note/AppointmentNotes";
import UploadAppointmentDocument from "../../../appointment/modals/UploadAppointmentDocument";
import {Views} from "react-big-calendar";
import TimezoneUtil from "../../../../utils/TimezoneUtil";
import AuthService from "../../../../services/auth-service/AuthService";
import InsuranceIcon from "../../../insurance/InsuranceIcon";
import Tooltip from "../../../../components/tooltip/Tooltip";
import EnumCancellationReason from "../../../appointment/enums/EnumCancellationReason";
import EnumAppointmentSource from "../../enums/EnumAppointmentSource";

class PatientAppointmentTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            appointments: [],
            expandedAppointments: null,
            selectedRows: []
        };
    }

    componentDidMount() {
        this.retrieveAppointments();
    }

    retrieveAppointments() {
        let appointmentId = this.props.appointmentId;
        let selectedView = this.props.selectedView;
        let selectedDate = this.props.selectedDate;

        RemotingService.getRemoteCall('api/appointment/list',
            {
                patientId: this.props.patientId,
                orderBy: 'startTime',
                orderDirection: 'DESC'
            },
            (result) => {
                let appointments = result.items;
                let expandedAppointments = {};

                if (appointmentId) {
                    expandedAppointments[appointmentId] = true;
                }

                this.setState({appointments, expandedAppointments, appointmentId, selectedView, selectedDate});
                this.highlightAppointments();
            });
    }

    onNoteAdded(appointment, note) {
        appointment.notes.push(note);
        this.expandAppointment(appointment.id);
    }

    highlightAppointments() {
        if (this.props.appointmentId) {
            let selectedRows = [...this.state.selectedRows];
            let selectedAppointment = this.state.appointments.filter(appointment => appointment.id == this.props.appointmentId)[0];
            selectedRows.push(selectedAppointment);
            this.setState({...this.state, selectedRows: selectedRows})
            this.props.scrollToAppointmentTable();
        }
    }

    expandAppointment(appointmentId) {
        let expandedAppointments = {...this.state.expandedAppointments};
        expandedAppointments[appointmentId] = true;
        this.setState({expandedAppointments});
    }

    replaceAppointment(appointment) {
        let expandedAppointments = {...this.state.expandedAppointments};
        expandedAppointments[appointment.id] = undefined;
        let appointments = this.state.appointments.map(a => a.id === appointment.id ? appointment : a);
        this.setState({appointments, expandedAppointments}, () => {
            expandedAppointments[appointment.id] = true;
            this.setState({expandedAppointments})
        });
        // TODO replacing appointment wont refresh expansion by default so closing and and re-opening it as workaroud (add note wont render new note otherwise)
    }

    patientTypeTemplate(rowData) {
        const patientType = EnumAppointmentType[rowData.patientType];
        return <>
            <FontAwesomeIcon icon={["fas", "circle"]} size={"2x"}
                             style={{
                                 color: patientType.color,
                                 maxHeight: "20px",
                                 maxWidth: "20px"
                             }}/>{" " + patientType.name}</>;
    }

    insuranceExistsTemplate(rowData) {
        return <InsuranceIcon insuranceExists={rowData.insuranceExists} isCustomInsurance={rowData.isCustomInsurance}/>
    }

    statusTemplate(rowData) {
        let appointmentStatus = EnumAppointmentStatus[rowData.status];
        let cancellationReason = rowData.cancellationReason;
        return (
            <div className={"d-flex"}>
                <Badge className="TableColBadge" style={{
                    backgroundColor: appointmentStatus.bgColor,
                    color: appointmentStatus.textColor,
                    fontSize: 14,
                    padding: 9,
                }}>{appointmentStatus.name}
                </Badge>
                {cancellationReason && <Tooltip key={cancellationReason} title={EnumCancellationReason[cancellationReason]}>
                    <img className={`appointment-table-cancellation-reason-icon`}
                        src={process.env.PUBLIC_URL + `/assets/cancel_reasons/${cancellationReason}.png`} alt=""/>
                </Tooltip>}
            </div>);
    }

    attachmentTemplate = (appointment) => {
        return (
            <div style={{display: "inline"}}>
                <ButtonGroup size={"sm"} className="w-100">
                    <Button variant={"primary"} size={"sm"}
                            onClick={() => this.showAppointmentDocuments(appointment)}>
                        Attachments{appointment.documentCount ? "(" + appointment.documentCount + ")" : ""}
                        <FontAwesomeIcon icon={["fas", "paperclip"]} style={{marginLeft: '5px'}}/>
                    </Button>
                    <Button variant={"primary"} size={"sm"}
                            onClick={() => this.showDocumentUpload(appointment)}
                            disabled={!AuthService.userHasAuthority("UPDATE_APPOINTMENT")}>
                        <FontAwesomeIcon icon={["fas", "file-upload"]}/>
                    </Button>
                </ButtonGroup>

            </div>
        );
    }

    appointmentExpansionTemplate(appointment) {
        return (
            <div className="appointment-expansion-wrapper row" style={{padding: "20px", animation: "fadeAnimation 1s"}}>
                <div className="col-12">
                    <AppointmentNotes appointment={appointment} onRefreshNotes={(notes) => {
                        appointment.notes = notes;
                        this.replaceAppointment(appointment);
                    }}/>
                </div>
            </div>
        );
    }

    goToCalendar(appointment) {
        let link = '/appointment?id=' + appointment.id;

        // If we landed on this page from a specific view of calendar, then go back to same view, prefer 'list' view if appointment is CANCELLED or NO_SHOW and 'day' view otherwise.
        if (this.props.selectedView) {
            link += '&selectedView=' + this.props.selectedView;
        } else {
            link += '&selectedView=' + (this.state.appointmentId === appointment.id ? this.state.selectedView : ("NO_SHOW" === appointment.status || "CANCELLED" === appointment.status) ? Views.AGENDA : Views.DAY);
        }
        link += '&selectedDate=' + (this.state.appointmentId === appointment.id ? this.state.selectedDate : DateUtil.formatDate(new Date(appointment.start)));
        link += '&selectedStaff=' + appointment.staff.id;

        this.props.history.push(link);
    }

    showAppointmentDocuments = (appointment) => {
        this.setState({selectedAppointment: appointment, appointmentDocumentModalVisible: true});
    }

    showDocumentUpload = (appointment) => {
        this.setState({selectedAppointment: appointment, documentUploadModalVisible: true});
    }

    renderAppointmentDocumentsModal = () => {
        return this.state.appointmentDocumentModalVisible ?
            <Modal visible={true}
                   header="Appointment Documents"
                   closeAction={() => this.setState({
                       appointmentDocumentModalVisible: false
                   })}>
                <AppointmentDocuments appointmentId={this.state.selectedAppointment.id}
                                      onDocumentDelete={appointmentId => {
                                          this.onDocumentDelete(appointmentId)
                                      }}/>
            </Modal>
            : null;
    }

    renderAppointmentDocumentUploadModal = () => {
        return this.state.documentUploadModalVisible ?
            <UploadAppointmentDocument appointmentId={this.state.selectedAppointment.id}
                                       close={() => this.setState({documentUploadModalVisible: false})}
                                       onDocumentUploaded={(appointmentId) => this.onDocumentUploaded(appointmentId)}/>
            : null;
    }

    onDocumentUploaded = (appointmentId) => {
        let appointment = this.state.appointments.find(item => item.id == appointmentId);
        appointment.documentCount++;
        this.setState({
            appointments: [...this.state.appointments],
            documentUploadModalVisible: false,
            selectedAppointment: undefined
        });
    }

    onDocumentDelete = (appointmentId) => {
        let appointment = this.state.appointments.find(item => item.id == appointmentId);
        appointment.documentCount--;
        this.setState({appointments: [...this.state.appointments]});
    }

    appointmentStartTime = (appointment) => {
        return AppointmentUtil.appointmentStartTime(appointment) + ` (${TimezoneUtil.getClinicZoneInfo(appointment.room.clinicName).code})`;
    }

    render() {
        return (
            <div className="card" style={{paddingTop: "10px"}}>
                <div className="card-body" style={{padding: 0}}>
                    <DataTable className="AppointmentsTable" responsive autoLayout
                               value={this.state.appointments}
                               expandedRows={this.state.expandedAppointments}
                               rowHover={true} selectionMode={"multiple"}
                               selection={this.state.selectedRows}
                               onSelectionChange={e => {
                                   let newSelectedRows = [...this.state.selectedRows];
                                   let selectedRow = e.value[0];
                                   if (newSelectedRows.includes(selectedRow)) {
                                       newSelectedRows.splice(newSelectedRows.indexOf(selectedRow), 1);
                                   } else {
                                       newSelectedRows.push(e.value[0]);
                                   }
                                   this.setState({selectedRows: newSelectedRows})
                               }}
                               onRowToggle={(e) => {
                                   let selectedRowIds = Object.keys(e.data);
                                   let selectedRows = [];
                                   selectedRowIds.forEach((id) => {
                                       let toggledRow = this.state.appointments.filter(appointment => appointment.id === id)[0];
                                       selectedRows.push(toggledRow)
                                   })
                                   this.setState({
                                       ...this.state,
                                       expandedAppointments: e.data,
                                       selectedRows: selectedRows
                                   })
                               }}
                               rowExpansionTemplate={(row) => this.appointmentExpansionTemplate(row)}
                               dataKey="id" paginator={true} paginatorPosition="bottom">
                        <Column expander={true} style={{width: '30px'}}/>
                        <Column header="Date" field="start" sortable
                                body={(rowData) => DateUtil.formatDateMMM(rowData.start)}
                                style={{width: "100px"}}/>
                        <Column header="Time" body={this.appointmentStartTime}
                                style={{width: "80px"}}/>
                        <Column header="Duration"
                                body={(rowData) => {
                                    const durationMinutes = moment.duration(moment(rowData.end)
                                        .diff(moment(rowData.start))).minutes();
                                    const durationHours = moment.duration(moment(rowData.end)
                                        .diff(moment(rowData.start))).hours()

                                    return (`${durationHours ? (durationHours === 1 ? durationHours + " hour" : durationHours + " hours") : ""} ` +
                                        `${durationMinutes ? durationMinutes + " minutes" : ""}`)

                                }}
                                style={{width: "90px"}}/>
                        <Column header="Provider" body={(rowData) => rowData.staff.name}/>

                        <Column field="patientType" header="Type" body={this.patientTypeTemplate}
                                style={{width: "140px"}}/>
                        <Column header="Package" body={() => '-'} style={{width: "70px"}}/>
                        <Column field="insuranceExists" header="Insurance"
                                body={this.insuranceExistsTemplate} style={{width: "90px"}}/>
                        <Column header="Sponsorship" body={() => '-'} style={{width: "100px"}}/>
                        <Column header="Location" body={(rowData) => rowData.room.clinicName}
                                style={{width: "70px"}}/>
                        <Column header="Status" body={this.statusTemplate} style={{width: "100px"}}/>

                        <Column header="Added By" body={(rowData) => rowData.bookedBy?.name}/>
                        <Column header="Source"
                                body={(rowData) => rowData.source ? EnumAppointmentSource[rowData.source] : ''}
                                style={{width: "100px"}}/>
                        <Column header="Attachments" body={this.attachmentTemplate} style={{width: "170px"}}/>
                        <Column header="Notes" style={{width: "120px"}}
                                body={(rowData) => <AddNoteButton appointment={rowData} className="w-100"
                                                                  onNoteAdded={(note) => this.onNoteAdded(rowData, note)}/>}/>
                        <Column header="Go To" style={{width: "80px", cursor: "pointer"}}
                                body={(rowData) => <FontAwesomeIcon icon={["fas", "calendar-alt"]}
                                                                    onClick={() => this.goToCalendar(rowData)}
                                                                    size="2x"
                                                                    style={{marginLeft: "25px"}}/>}/>
                    </DataTable>

                    {this.renderAppointmentDocumentsModal()}
                    {this.renderAppointmentDocumentUploadModal()}

                </div>
            </div>
        );
    }

}

export default PatientAppointmentTable;
