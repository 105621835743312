import Enum from "../../../enums/Enum";

const EnumAppointmentType = new Enum({
    NEW_PATIENT: {
        name: "New Complaint",
        color: "#3B86FF",
        bgColor: "#3691d4",
        textColor: "#FFFFFF",
        cutBgColor: "#dcf7ac"
    },
    FOLLOW_UP: {name: "Follow Up", color: "#0BC304", bgColor: "#87B42C", textColor: "#FFFFFF", cutBgColor: "#ececbb"},
    TELECONSULTATION: {
        name: "Teleconsultation",
        color: "#2E7A6D",
        bgColor: "#9ACBBE",
        textColor: "#FFFFFF",
        cutBgColor: "#ececbb"
    },
    NEW_COMPLAINT: {
        name: "New Complaint",
        color: "#3B86FF",
        bgColor: "#3691D4",
        textColor: "#FFFFFF",
        cutBgColor: "#d3ecf2"
    }, // TODO remove NEW_COMPLAINT or NEW_PATIENT
    WORK: {name: "Work", bgColor: "#3691D4", textColor: "#FFFFFF", cutBgColor: "#d3ecf2"} // TODO ???
});

EnumAppointmentType.selectableTypes = [EnumAppointmentType.FOLLOW_UP, EnumAppointmentType.NEW_PATIENT];

export default EnumAppointmentType;
